@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Urbanist:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --font-latoblack: "Lato Black", sans-serif;
  --font-latobold: "Lato Bold", sans-serif;
  --font-latoregular: "Lato Regular", sans-serif;
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-Regular.otf");
}

@font-face {
  font-family: "Intro Regular Alt";
  src: url("./assets/fonts/Intro\ Regular\ Alt.otf");
}

@font-face {
  font-family: "Sparky Stones";
  src: url("./assets/fonts/SparkyStonesRegular-BW6ld.ttf");
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Konnect";
  src: url("./assets/fonts/Konnect-Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: "Elephant Black";
  src: url("./assets/fonts/Elephant Black.ttf");
}

@font-face {
  font-family: "Spartan Book Classified";
  src: url("./assets/fonts/Spartan-BookClassified.otf");
}

@font-face {
  font-family: "Heebo VariableFont wght";
  src: url("./assets/fonts/Heebo-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Lato Black";
  src: url("./assets/fonts/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato Bold";
  src: url("./assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato Regular";
  src: url("./assets/fonts/Lato-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Remove map label at the bottom of the screen */
.gmnoprint,
.gm-style-cc {
  display: none;
}
