:root {
  --color-bg: #f7feff;
  --color-primary: #044271;
  --color-field: #f0f6fb;
  --color-gray1: #728ea7;
  --color-white: #ffffff;
  --color-stroke: #dbecee;
  --color-red: #ef4054;
  --color-red-10: rgba(239, 64, 84, 0.1);
  --font-latoblack: "Lato Black", sans-serif;
  --font-latobold: "Lato Bold", sans-serif;
  --font-latoregular: "Lato Regular", sans-serif;
  --gutter-x: 12px;
  --gutter-y: 12px;
}

.popupWrapper * {
  box-sizing: border-box;
}

.popupWrapper {
  display: flex;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 64px 0;
  overflow-y: auto;
  background-color: var(--popout-color, rgba(4, 66, 113, 0.6));
  z-index: 999999;
}

.popupDialog {
  width: 100%;
  max-width: 720px;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  animation: popupFadeIn 0.5s ease forwards;
}

.popupDialogScrolling {
  max-height: 100%;
  overflow: hidden;
}

.popupHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
}

.popupTitle {
  flex: 1;
  font-family: var(--font-latobold);
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;
  color: var(--color-primary);
}

.popupClose {
  width: 24px;
  height: 24px;
  justify-self: flex-end;
  cursor: pointer;
}

.popupBody {
  padding: 0 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
}

.popupFooter {
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.userPrizeTitle {
  font-family: var(--font-latoblack);
  font-weight: 900;
  font-size: 24px;
  line-height: 135%;
  color: var(--color-primary);
  text-align: center;
}

.userPrizeDescription {
  margin-top: 8px;
  font-family: var(--font-latoregular);
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: var(--color-gray1);
  text-align: center;
}

@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
